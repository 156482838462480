<template>
  <div class="filesSelect dp-f ai-c" v-loading="loading" :element-loading-background="config.loading.background">
    <template v-if="state.uploadType !== 1 || nowflielength == 0">
      <el-upload class="mr-12" :accept="file_accept" :show-file-list="false" :http-request="httpRequest">
        <div class="box file" v-show="nowflielength == 0">
          <div class="bfont" style="color:#0088FF;">
            上传文件
          </div>
          <div class="sfontbox dp-f ai-c"><img class="mr-4" src="@/assets/img/initiateContract/add3.png" />点击上传</div>
        </div>
        <div class="butbox" v-show="nowflielength != 0">
          <img src="@/assets/img/initiateContract/fileicon.png">
          上传文件
        </div>
      </el-upload>
      <div class="box picture mr-12 cu-p" v-show="nowflielength == 0" @click="unref(imgsRef).show()">
        <div class="bfont" style="color:#5C81FF;">
          上传图片
        </div>
        <div class="sfontbox dp-f ai-c"><img class="mr-4" src="@/assets/img/initiateContract/add3.png" />点击上传</div>
      </div>
      <div class="butbox butbox2 mr-12 cu-p" v-show="nowflielength != 0" @click="unref(imgsRef).show()">
        <img src="@/assets/img/initiateContract/img.png">
        上传图片
      </div>
      <!-- <el-upload class="mr-12" :accept="img_accept" :show-file-list="false" :http-request="httpRequestImg">
      </el-upload> -->
    </template>
    <template v-if="userInfo.sysCompany&&(state.uploadType !== 0 || nowflielength == 0)">
      <div class="stencil cu-p" v-show="nowflielength == 0" @click="unref(templateChooseRef).show()">
        <div class="bfont" style="color:#FF8100;">
          选择在线模版
        </div>
        <div class="sfontbox dp-f ai-c"><img class="mr-4" src="@/assets/img/initiateContract/add3.png" />点击选择</div>
      </div>
      <div class="butbox butbox3" v-show="nowflielength != 0" @click="unref(templateChooseRef).show()">
        <!-- <img src="@/assets/img/initiateContract/template.png"> -->
        <img src="@/assets/img/initiateContract/fileicon.png">
        选择在线模版
      </div>
    </template>
    <div class="maxlength ml-10" v-show="nowflielength != 0"><span class="fw-b">{{ nowflielength }}</span> / {{
      state.maxlength }}</div>
  </div>
  <div class="w100 dp-f fw-w">
    <div v-for="(el, ind) in state.contracts" :key="ind" class="contractitembox mt-14 mr-10">
      <div class="contractitem pt-r pt-15">
        <div v-if="el.type == 1" class="pt-a teplateName">{{ el.teplateName }}</div>
        <img class="del pt-a cu-p" src="@/assets/img/initiateContract/del.png" @click="state.contracts.splice(ind, 1),delClick(el)">
        <img class="contractImg cu-p" :src="el.pages[0].fileUrl" @click="dialogImageUrl = el.pages.map(item => {
          return item.fileUrl
        }), dialogVisibleImage = true">
        <div class="introduce w100 h-40 pt-a dp-fc pr-12 pl-12 isupdate">
          <el-input class="" v-if="state.isupdate == ind" v-model="el.fileChname"></el-input>
          <div class="t-oh m-0-a c-333" v-else>{{ el.fileChname }}</div>
          <img class="w-16 h-16 cu-p" src="@/assets/img/general/update.png"
            @click="state.isupdate == ind ? state.isupdate = -1 : state.isupdate = ind">
        </div>
      </div>
      <div v-if="el.type == 1" class="batch w100  ta-c">
        批量发起 <span>{{ el.count }}</span> 份合同
      </div>
    </div>
  </div>
  <!-- @click="dialogImageUrl=el.pics,dialogVisibleImage=true" -->
  <!--图片弹窗-->
  <el-image-viewer :url-list="dialogImageUrl" v-if="dialogVisibleImage" @close="dialogVisibleImage = false" />
  <imgs ref="imgsRef" @Emit="getimgs"></imgs>
  <templateChoose ref="templateChooseRef" @saveEmit="templateEmit"></templateChoose>
</template>

<script setup>
import { ref, unref, reactive, computed, defineExpose,defineEmits,watch } from "vue";
import { handleMessage } from "@/utils/server/confirm";
import { file_accept } from "@/utils/base/accept.js";
import { getfileTypeName } from "@/utils/server/file.js"
import { useStore } from 'vuex';
import { fileByPdf, getpdfByPic, uploadFile } from "@/utils/server/upload.js"
import imgs from "./filesSelect/imgs.vue"
import templateChoose from "./template/choose.vue"
const store= useStore()
const emit = defineEmits(['templateEmit','fileEmit'])
const imgsRef = ref()
const templateChooseRef = ref()
const dialogImageUrl = ref([])//预览图片路径
const dialogVisibleImage = ref(false)//缩略图预览弹框
const loading = ref(false);//loading 显示
const state = reactive({
  // type 0 单文件添加 1 模板批量添加 
  contracts: [],//所有合同数据
  maxlength: 50,//最大合同数量
  isupdate: -1,//当前编辑合同名称的下标
  uploadType: false,//上传类型（0 其他，1 模板）
});
const userInfo = JSON.parse(window.sessionStorage.user).user_info
watch(() => store.state.initiate.fileLoading, (value, oldValue) => {
  loading.value=value
})
const delClick=(el)=>{
  if(el.type==1)
    emit('fileEmit')
}
//当前已添加文件数量
const nowflielength = computed(() => {
  return state.contracts.reduce((total, item) => {
    if (item.type == 0)
      return total + 1
    else if (item.type == 1)
      return total + item.count
  }, 0)
})
// 文件上传
const httpRequest = (rawFile) => {
  let fileTypeName = getfileTypeName(rawFile.file.name)
  let filetype = ['pdf', 'PDF', 'docx', 'doc', 'DOCX', 'DOC', 'xls', 'xlsx', 'XLS', 'XLSX', 'word', 'WORD', 'txt', 'TXT']
  // console.log('文件上传rawFile11', rawFile, fileTypeName)
  // uploadFile(rawFile.file, fileTypeName)
  if (rawFile.file.size / 1024 / 1024 > 500) {
    handleMessage('文档大小超过500MB')
  } else if (filetype.includes(fileTypeName)) {
    state.uploadType = 0
    loading.value = true
    // type:要转换的文件类型(0 word , 1 图片)
    fileByPdf(0, [rawFile.file]).then(res => {
      console.log(111, res, rawFile.file.name)
      if (res.code == 200) {
        getpdfByPic(rawFile.file.name, res.data.fileUrl).then((res2) => {
          addContracts(rawFile.file.name, undefined, res2.data.map(item => {
            return {
              fileUrl: item.fileUrl,
              id: item.id
            }
          }), res.data.id)
          loading.value = false
        }).catch(() => {
          loading.value = false
        })
      } else {
        loading.value = false
      }
    }).catch(() => {
      loading.value = false
    })
  } else {
    handleMessage('请上传正确文档格式格式!')
  }
}
const getimgs = (imgs) => {
  // console.log('imgs',imgs)
  let files = imgs.map(item => {
    return item.file
  })
  state.uploadType = 0
  loading.value = true
  fileByPdf(1, files).then(res => {
    if (res.code == 200) {
      getpdfByPic(imgs[0].name, res.data.fileUrl).then((res2) => {
        addContracts(imgs[0].name, undefined, res2.data.map(item => {
          return {
            fileUrl: item.fileUrl,
            id: item.id
          }
        }), res.data.id)
        unref(imgsRef).reset()
        loading.value = false
      }).catch(() => {
        loading.value = false
        unref(imgsRef).reset()
      })
    } else {
      loading.value = false
      unref(imgsRef).reset()
    }
  }).catch(() => {
    loading.value = false
    unref(imgsRef).reset()
  })
}
const templateEmit = (baseInfo) => {
  console.log("saveEmit3:", baseInfo)
    getpdfByPic(baseInfo.fileChname, baseInfo.fileUrl).then((res2) => {
      addContracts(baseInfo.fileChname, undefined, res2.data.map(item => {
        return {
          fileUrl: item.fileUrl,
          id: item.id
        }
      }), baseInfo.id)
      // unref(imgsRef).reset()
      loading.value = false
    }).catch(() => {
      loading.value = false
      // unref(imgsRef).reset()
    })
}
// const templateEmit = (baseinfo,Datas) => {
//   state.uploadType = 1
//   state.contracts.splice(0)
//   state.contracts.push(baseinfo)
//   emit('templateEmit',Datas)
// }
// 添加合同文件
const addContracts = (name, type = 0, pages = [], id) => {
  console.log('添加合同文件',pages)
  state.contracts.push({
    type: type,//0 默认发起 1批量发起
    fileChname: name,//文件中文名
    id: id,
    signaturePositions: [],//所有签署方位置信息
    watermark: [],//所有水印数据
    isSetSignature: 0,//签章位置 0 不指定位置 1指定位置
    isSetAutograph: 0,//签名位置 0 不指定位置 1指定位置
    isSetDate: 0,//日期位置 0 不指定位置 1指定位置
    isSetPagInSeal: 0,//骑缝章 0 不需要 1需要
    pages: pages,
    count: '',//数量
  })
  console.log("contracts: ", state.contracts)
}
const getuploadType = (() => {
  return state.uploadType
})
defineExpose({
  state,
  filesContracts: state.contracts,
  getuploadType,
});
</script>


<style lang="scss" scoped>
@import "@/styles/color/value.scss";
.contractitembox {
  width: 164px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;

  .batch {
    font-size: 16px;
    color: #333333;

    span {
      color: rgba(255, 0, 0, 1);
    }
  }
}

.contractitem {
  width: 100%;
  height: 192px;
  background: #EDEDED;
  border-radius: 6px;
  display: flex;
  justify-content: center;

  .teplateName {
    left: 7px;
    top: 7px;
    padding: 0 13px;
    line-height: 26px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 13px;
  }

  .introduce {
    bottom: 0;
    background: #EFC875;
    box-shadow: 0px -2px 6px 0px rgba(250, 173, 20, 0.22);
    border-radius: 0 0 6px 6px;
  }

  .contractImg {
    max-width: 135px;
    max-height: 100%;
  }

  .del {
    top: -14px;
    right: -14px;
  }
}

::v-deep .el-upload-list {
  display: none;
}

.filesSelect {
  .sfontbox {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }

  .bfont {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
  }

  .maxlength {
    background: #D8D8D8;
    border-radius: 11px;
    line-height: 20px;
    padding: 1px 12px;
  }

  .box {
    background-size: 100% 100%;
    width: 232px;
    height: 118px;
    padding: 21px 18px;
  }

  .file {
    background: url("../../../../assets/img/initiateContract/file.png") no-repeat;
  }

  .picture {
    background: url("../../../../assets/img/initiateContract/picture.png") no-repeat;
  }

  .stencil {
    background: url("../../../../assets/img/initiateContract/stencil.png") no-repeat;
    width: 232px;
    height: 118px;
    padding: 21px 18px;
  }
}

.butbox {
  padding: 0 14px;
  height: 44px;
  display: flex;
  align-items: center;
  background: rgba(0, 123, 255, 0.1);
  border-radius: 6px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0088FF;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}

.butbox2 {
  background: rgba(92, 129, 255, 0.1);
  color: #5C81FF;
}

.butbox3 {
  background: rgba(255, 129, 0, 0.1);
  color: #FF8100;
}

// 合同名称编辑情况
.isupdate {
  ::v-deep .el-input__wrapper {
    background-color: transparent;
    box-shadow: none;
  }
}
</style>