<template>
 <!-- state.carbonCopyPeoples: {{ state.carbonCopyPeoples }} -->
  <div class="dp-f ai-c" v-show="state.carbonCopyPeoples.length==0">
    <div class="btn mr-16" @click="openhint = true, state.peopleList = [{ copySend: '', phone: '' }]">
      <el-image class="w-16 h-16 mr-4" :src="require('@/assets/img/initiateContract/add.png')"></el-image>
      <span class="fs-14">添加抄送人</span>
    </div>
    <span class="c-999 fs-12">文件签署完成后抄送，抄送人可查看、下载（不超过10人）</span>
  </div>
  <div class="dp-f fw-w ai-c" v-show="state.carbonCopyPeoples.length>0">
    <div class="tag mr-10 pt-r cu-p mb-10" :class="state.carbonIndex==index?'tagac':''" v-for="(item, index) in state.carbonCopyPeoples" :key="index" @click="state.carbonIndex=index">
      {{ item.copySend + ' ' + item.phone }}
      <img class="icon pt-a"  src="@/assets/img/initiateContract/del2.png" @click="state.carbonCopyPeoples.splice(index,1),state.carbonIndex=-1">
    </div>
    <img class="w-28 h-28 cu-p" v-show="state.carbonCopyPeoples.length<state.maxlength" src="@/assets/img/initiateContract/add4.png" @click="openhint = true, state.peopleList = [{ copySend: '', phone: '' }]">
  </div>
  <commonPop popWidth=649px :openhint=openhint titleText=添加抄送人 @cancel='openhint = false'>
    <template v-slot:el-popBody>
      <el-form ref="ruleFormRef" :model="state.peopleList" :rules="rules">
        <div class="peopleList">
          <div class="dp-f ai-c mb-20" v-for="(item, index) in state.peopleList" :key="index">
            <span class="fs-14 fw-b">抄送人{{ index+ 1}}</span>
            <div class="ml-10">
              <el-form-item :prop="index + '.copySend'" :rules="rules.copySend">
                <el-input style="width:138px" v-model="item.copySend" placeholder="抄送人姓名" />
              </el-form-item>
            </div>
            <div class="ml-10">
              <el-form-item :prop="index + '.phone'" :rules="rules.phone">
                <el-input style="width:307px" v-model="item.phone" placeholder="抄送人手机号" />
              </el-form-item>
            </div>
            <el-image class="w-32 h-32 ml-10 cu-p" @click="state.peopleList.splice(index, 1)" v-if="index != 0"
              :src="require('@/assets/img/initiateContract/close.png')"></el-image>
          </div>
          <div class="btn mr-16" @click="add"
            v-if="state.peopleList.length + state.carbonCopyPeoples.length < state.maxlength">
            <el-image class="w-16 h-16 mr-4" :src="require('@/assets/img/initiateContract/add.png')"></el-image>
            <span class="fs-14">添加抄送人</span>
          </div>
        </div>
      </el-form>
    </template>
    <template v-slot:el-footer>
      <div class="footer">
        <oabutton class="mb-20" title="确认" width=110 height=52 CSStype=2 @buttonclick="submitForm"></oabutton>
      </div>
    </template>
  </commonPop>
</template>

<script setup>
import { ref,unref, reactive } from "vue";
import commonPop from "@/components/dialog/commonPop.vue";
import { validPhone } from "@/utils/server/validate.js";
let openhint = ref(false);//添加抄送人弹框显示
const ruleFormRef=ref()//表单ref
let state = reactive({
  peopleList: [],//当前弹框编辑抄送人数据
  carbonCopyPeoples: [],//抄送人数据集合
  carbonIndex:1,//当前选中抄送人下标
  maxlength: 10,//抄送人最大数量
});
// 校验手机号格式
const validPhoneData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入抄送人手机号'))
  } else {
    if (validPhone(value)) {
      callback()
    } else
      callback(new Error('手机号格式错误'))
  }
}
const rules = reactive({
  phone: [
    { validator: validPhoneData, trigger: 'blur' }
  ],
  copySend: [
    { required: true, message: "请输入抄送人姓名", trigger: "blur" },
  ],
});
const add = () => {
  state.peopleList.push({
    copySend: "",//抄送人
    phone: "",//手机号
  });
};
// 保存抄送人数据
const saveCopySendData = () => {
  state.carbonCopyPeoples=[
    ...state.carbonCopyPeoples,
    ...state.peopleList,
  ]
  // state.carbonCopyPeoples.splice(state.peopleList.length - 1, 0, state.carbonCopyPeoples)
  openhint.value = false;
}
// 表单提交
const submitForm = async () => {
  console.log('表单ref',ruleFormRef)
  const form = unref(ruleFormRef)
  if (!form) return
  await form.validate((valid) => {
    if (valid) {
      saveCopySendData()
    }
  })
}
const getData=(()=>{
   return state.carbonCopyPeoples
})
defineExpose({
  state,
  getData
});
</script>

<style lang="scss" scoped>
.btn {
  padding: 0 10px;
  height: 34px;
  display: flex;
  align-items: center;
  background-color: rgba(22, 119, 255, 0.1);
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;

  span {
    color: #1677FF;
  }
}

.tag {
  padding: 6px 15px;
  line-height: 22px;
  border-radius: 17px;
  background-color: #F2F2F2;
  .icon{
    display: none;
  }
}
.tagac{
  background-color: rgba(22, 119, 255, 0.1);
  .icon{
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    top: -5px;
    right: -4px;
    background: #1677FF;
    border-radius: 50%;
    color: #fff;
  }
}

.peopleList {
  ::v-deep .el-input__inner {
    height: 40px;
  }
  
  ::v-deep .el-form-item__content {
    margin-left: 0px !important;
  }
  max-height: 455px;
  min-height: 300px;
  overflow: auto;
}

.footer {
  display: flex;
  justify-content: flex-end;

  ::v-deep .el-upload-list {
    display: none;
  }
}
</style>