<template>
  <el-dialog class="operation_dialog bgac_dialog abox" v-model="dialogVisible" :title="dialogtitle" :close-on-click-modal="false"
    width="1020px">
    <div class="dp-f mt-10">
      <div class="w-194 mr-40 h-32 sinputbox">
        <el-input v-model="state.searchKey.input" placeholder="搜索模版名称">
          <template #append>
            <div class=" h100 dp-fc">
              <span class="icon iconfont icon-sousuo cu-p" @click="getdataList(currentPage)"></span>
            </div>
          </template>
        </el-input>
      </div>
      <!-- <div>模版类型：</div>
      <el-select placeholder=请选择 v-model="state.searchKey.contractType">
        <el-option v-for="(el, ind) in state.contractTypeList" :key="ind" :value="el.value" :label="el.label" />
      </el-select> -->
      <!-- <div class="ml-at checkbox">
        <el-checkbox v-model="state.searchKey.checked" label="仅看我的模版" size="large" />
      </div> -->
    </div>
    <div class="choosebox dp-f fw-w mt-24" v-loading="loading" :element-loading-text="config.loading.text"
      :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
      <div v-for="(el,ind) in state.templateData" :key="ind" class="w-200 h-234 pt-r chooseItem cu-p dp-fc pt-10 pl-10 pr-10" :class="state.acIndex==ind?'chooseItemAc':''" @click="state.acIndex=ind">
        <img :src="el.sysFile.fileUrl">
        <!-- <div class="iconbox pt-a">日常通知</div> -->
        <div class="pt-a titlebox ta-c">{{el.templateTitle}}</div>
      </div>
    </div>
    <el-pagination class="dp-f jc-c mb-20" @current-change="handleCurrentChange" background
			layout="prev, pager, next,slot" :current-page="currentPage" :total="totalPage">
		</el-pagination>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <!-- {{ state.templateData[state.acIndex] }} -->
        <oabutton class="searcML" width='120' height='40' title="确定" CSStype=2
          @buttonclick="dialogloading=true,unref(detailRef).show(state.templateData[state.acIndex].id,state.templateData[state.acIndex].templateTitle)" v-model:loading="dialogloading"/>
        <oabutton class="searcML" width='120' height='40' title="取消" CSStype=3 @buttonclick="dialogVisible = false"/>
      </span>
    </template>
  </el-dialog>
  <detail ref="detailRef" @saveEmit="saveEmit" @gbEmit="dialogVisible = false" @setLoading="dialogloading=$event"></detail>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits, computed } from 'vue'
import { httpToken } from "@/utils/request";
import qs from "qs";
import { handleMessage } from "@/utils/server/confirm";
import detail from './detail.vue'
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogtitle = ref();
const dialogVisible = ref(false)//弹框显示
const emit = defineEmits(['Emit','saveEmit'])
const id = ref(0);//操作的id
const detailRef=ref()
const currentPage = ref(1); //当前页数
const totalPage = ref(0); //总条数
const loading =ref(false)

const state = reactive({
  acIndex:0,//当前选中下标
  contractTypeList: [],//合同类型下拉
  templateData:[],//当前展示模板数据
  searchKey:{
    input:'',
    contractType:'',//合同类型
    checked:'',
  },//搜索框绑定值
})
const saveEmit=(baseinfo)=>{
  dialogVisible.value = false
  emit('saveEmit',baseinfo)
}
const getdataList = (val,callback=()=>{}) => {
  loading.value = true;
  let json = [];
  if(state.searchKey.input){
    json.push({ column: "template_title", type: 'like',value:state.searchKey.input })
  }
  currentPage.value = val;
  httpToken({
    method: "post",
    url: "/admin/templateCon/list",
    data: qs.stringify({
      page: val,
      paramData: JSON.stringify(json),
    }),
  }).then((res) => {
    // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
    if (val !== res.data.current) {
      getdataList(res.data.current);
      return;
    }
    callback()
    totalPage.value = res.data.total;
    state.templateData = res.data.records;
    loading.value = false;
  })
  .catch(() => {
    loading.value = false;
  });
};
const handleCurrentChange=((val)=>{
  getdataList(val)
})
// 保存数据
const saveData=()=>{
  dialogVisible.value = false
}
const show = (() => {
  dialogloading.value=false
  dialogtitle.value = '选择在线模版'
  getdataList(1,()=>{
    dialogVisible.value = true
  })
})
defineExpose({
  show
});
</script>
<style lang="scss" scoped>
@import "@/styles/color/value.scss";
@import "@/styles/general/element/pagination2.scss"; //element 分页样式重置
.abox{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0,0,0,0.88);
  .choosebox{
    min-height: 500px;
    .chooseItem{
      color: $color-button;
      background: #EDEDED;
      border-radius: 7px;
      margin-right: calc((100% - 200px * 4)/3);
      margin-bottom: 30px;
      img{
        max-width: 100%;
        max-height: 100%;
      }
      .iconbox{
        left: 12px;
        top: 10px;
        width: 76px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255,255,255,0.88);
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 3px;
      }
      .titlebox{
        width: 100%;
        height: 40px;
        line-height: 40px;
        background-color: $active-theme;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        bottom: 0;
      }
    }
    :nth-child(4n).chooseItem{
      margin-right: 0;
    }
    .chooseItemAc{
      box-shadow: inset 0px 0px 10px 0px $active-theme;
    }
  }
}
::v-deep .el-input{
  height: 32px;
}
.sinputbox{
  ::v-deep .el-input__wrapper{
    box-shadow:none;
    border: 1px solid #dcdfe6;
    border-right: none;
  }
  ::v-deep .el-input-group__append{
    background-color: transparent;
    padding: 0 8px;
  }
}

.iconfont{
  color: #dcdfe6;
}
// @import "@/styles/general/element.scss"; //element 样式重置
</style>