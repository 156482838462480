<template>
  <workbenchbox>
    <div class="w100 h-20"></div>
    <!-- 在线模版数据{{ store.state.initiate }} -->
    <indicate />
    <div class="mt-30">
      <el-form ref="ruleFormRef" label-width="140px" :rules="rules" :model="state.ruleFormData">
        <div class="formbox formbox1">
          <!-- {{ state.ruleFormData.contractType }} -->
          <el-form-item label="合同类型:" required prop="contractType" v-if="state.typeshow">
            <el-select placeholder=请选择 v-model="state.ruleFormData.contractType">
              <el-option v-for="(el, ind) in state.contractTypeList" :key="ind" :value="el.value" :label="el.label" />
            </el-select>
          </el-form-item>
          <el-form-item label="合同主题:" prop="contractName">
            <el-input placeholder="请输入" v-model="state.ruleFormData.contractName"></el-input>
          </el-form-item>
          <el-form-item label="合同编号:" prop="contractNumber">
            <el-input placeholder="请输入编号（非必填）" v-model="state.ruleFormData.contractNumber"></el-input>
          </el-form-item>
        </div>
        <div class="mt-12 formbox">
          <el-form-item label="签署方:" required>
            <signatory ref="signatoryRef" v-model:filesContracts="state.filesContracts" />
          </el-form-item>
          <el-form-item label="签署顺序:" required v-if="state.signatorys.length > 0" prop="signOrder">
            <el-radio-group v-model="state.ruleFormData.signOrder">
              <el-radio :label="0">无序签署</el-radio>
              <el-radio :label="1">有序签署</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="文件抄送:">
            <peopleCopied ref="peopleCopiedRef" />
          </el-form-item>
        </div>
        <div class="mt-12 formbox">
          <el-form-item label="签约文件:" class="is-required">
            <!-- {{ state.filesContracts }} -->
            <!-- {{ unref(filesSelectRef).getuploadType() }} -->
            <files-select ref="filesSelectRef" @templateEmit="unref(signatoryRef).setTemplate(1,$event)" @fileEmit="unref(signatoryRef).setTemplate(0)"/>
          </el-form-item>
        </div>
        <div class="mt-12 formbox">
          <el-form-item label="签署截止日期:" prop="contractDeadline">
            <el-date-picker v-model="state.ruleFormData.contractDeadline" format="YYYY-MM-DD"
              value-format="YYYY-MM-DD" type="date" placeholder="请选择截止日期" :disabledDate="disabledDateFun"
              @focus="timefocus" @change="dateChange"/>
          </el-form-item>
          <el-form-item label="水印:">
            <div class="fs-14 cu-p" style="color:#1677FF" @click="watermarkClick">去设置水印</div>
          </el-form-item>
          <el-form-item label="添加备注:">
            <div class="textareaReset w100">
              <el-input v-model="state.ruleFormData.remarks" :rows="3" type="textarea" placeholder="添加备注，最多300字（非必填）"
                :maxlength="config.inputMaxlength" />
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="dp-f jc-c mt-40 pb-20">
      <oabutton class="mr-13" CSStype=3 width=160 height=52 title="保存为草稿" @buttonclick="submitForm(ruleFormRef, 4)"
        v-model:loading="state.loading" />
      <oabutton title=发起合同 CSStype=2 width=160 height=52 @buttonclick="submitForm(ruleFormRef, 1)"
        v-model:loading="state.loading" />
    </div>
    <signSetting ref="signSettingRef" />
    <watermark ref="watermarkRef" />
  </workbenchbox>
</template>

<script setup>
import { reactive, ref, unref, onMounted, computed, nextTick } from "vue";
import { getworkTypeData } from "@/utils/server/selectdata.js"
import { handleMessage } from "@/utils/server/confirm.js"
import { router_push_name } from "@/utils/server/router.js"
import { httpToken } from "@/utils/request";
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import qs from 'qs'
import workbenchbox from "@/components/layout/workbenchbox.vue";
import indicate from "@/components/layout/indicate.vue"; //头部引导
import filesSelect from "./components/filesSelect.vue"; //签约文件上传
import signatory from "./components/signatory.vue";  //签署方列表
import peopleCopied from "./components/peopleCopied.vue";  //文件抄送
import watermark from "./components/watermark.vue";  //水印设置
import signSetting from "./components/signSetting.vue" //签署设置
const ruleFormRef = ref(null); //表单验证
const signatoryRef = ref(null); //获取签署方列表
const signSettingRef = ref();// 签署设置Ref
const watermarkRef = ref();//水印设置ref
const peopleCopiedRef = ref()//文件抄送
const filesSelectRef = ref();//签约文件上传ref
const route = useRoute()
const store = useStore()
const state = reactive({
  loading: false,//loading显示
  contractId: false,//合同id
  contractTypeList: [],//合同类型下拉
  signatorys: [],//签署方列表
  filesContracts: [],//所有签约文件数据
  ruleFormData: {
    contractName: '',//合同名称(主题)
    contractType: "",//合同类型
    contractNumber: '',//合同编号
    contractDeadline: "",//截止日期
    remarks: "",//备注
    signOrder: 0,//签署顺序(0 无序，1 有序)
  },
  typeshow: false
});
const dateChange = ((e) => {
  console.log("dateChange: ", e)
})
store.commit('initiate/resetData')
// 数据回显
if (route.params.data) {
  console.log('发起合同当前路由信息', JSON.parse(route.params.data))
  let routeData = JSON.parse(route.params.data)
  httpToken({
    method: "post",
    url: '/initiate-contract/contract/findLaunch',
    data: qs.stringify({ paramData: JSON.stringify([{ column: 'id', type: 'eq', value: routeData.id }]) })
  }).then((res) => {
    state.contractId = res.data.contract.id
    // 基础信息
    state.ruleFormData.contractName = routeData.contractName
    state.ruleFormData.contractNumber = res.data.contract.contractNumber
    state.ruleFormData.contractType = routeData.contractType
    state.ruleFormData.contractDeadline = routeData.contractDeadline
    state.ruleFormData.remarks = res.data.contract.remarks
    state.ruleFormData.signOrder = routeData.signOrder
    // console.log('草稿件数据', res)
    // 签署方
    res.data.contractSignRecords.forEach(item => {
      if (item.requirements) {
        item.requirements = JSON.parse(item.requirements)
      }
      unref(signatoryRef).state.addressList.push(item)
    })
    // 文件抄送
    if (res.data.carbonCopyPeoples) {
      res.data.carbonCopyPeoples.forEach(item => {
        unref(peopleCopiedRef).state.carbonCopyPeoples.push(item)
      })
    }
    // 签约文件
    res.data.files.forEach(item => {
      unref(filesSelectRef).state.contracts.push(item)
    })
    unref(filesSelectRef).state.uploadType = res.data.contract.uploadType
    unref(signatoryRef).setUploadType(res.data.contract.uploadType)
  })
} else {
  //默认给数据
  nextTick(() => {
    unref(signatoryRef).fillIndata()
  })
  state.contractId = false
}
const disabledDateFun = ((time) => {
  return time.getTime() < new Date().getTime(); //选择今天之后的日期
})
// 隐藏此刻按钮
const timefocus = (() => {
  nextTick(() => {
    document.getElementsByClassName('el-picker-panel__link-btn')[0].setAttribute('style', 'display:none') // 隐藏分列
  })
})
if (JSON.parse(window.sessionStorage.user).user_info.companyId) {
  getworkTypeData().then(res => {
    state.contractTypeList = res
    state.typeshow = true
  })
}

const rules = reactive({
  contractType: [
    { required: true, message: "请选择合同类型", trigger: "blur" },
  ],
  contractName: [
    { required: true, message: "请填写合同主题", trigger: "blur" },
  ],
  radio: [
    { required: true, message: "请选择", trigger: "blur" },
  ],
  contractDeadline: [{ required: true, message: "请选择截止日期", trigger: "blur" }],
});
// 水印设置
const watermarkClick = (() => {
  if (state.filesContracts.length == 0) {
    handleMessage('至少添加一份签约文件')
    return
  }
  unref(watermarkRef).show(state.filesContracts)
})
// 设置印章
const signSettingClick = ((contractInfo) => {
  unref(signSettingRef).show(unref(signatoryRef).getData(), state.filesContracts, contractInfo)
})
onMounted(() => {
  state.filesContracts = computed(() => {
    return filesSelectRef.value.filesContracts;
  });
  state.signatorys = computed(() => {
    return signatoryRef.value.addressList;
  });
});
// 发起合同方法
const initiateContract = (status) => {
  nextTick(() => {
    console.log('state.ruleFormData.contractDeadline:',state.ruleFormData.contractDeadline)
    if (state.ruleFormData.contractDeadline.split(" ").length == 1) {
      state.ruleFormData.contractDeadline += " 23:59:59"      
    }
    console.log('state.ruleFormData.contractDeadline2:',state.ruleFormData.contractDeadline)
    console.log('signatoryRef.value.addressList', signatoryRef.value.addressList)
    let json = {
      contract: {
        ...state.ruleFormData,
        contractStatus: 4,
        uploadType: unref(filesSelectRef).getuploadType(),
      },//合同表
      contractSignRecords: JSON.stringify(unref(signatoryRef).getData()),//签署方集合
      carbonCopyPeoples: JSON.stringify(unref(peopleCopiedRef).getData()),//抄送人（非必填）
      // signaturePosition :{},//签署位置集合
      // watermark:{},//水印表（非必填）
      contractFiled: JSON.stringify([]),//模板动态字段关联表（非必填）
      files: JSON.stringify(state.filesContracts)
    }
    state.loading = true
    // http://192.168.31.136:9999/initiate-contract/contract/add
    let url = '/initiate-contract/contract/add'
    if (state.contractId) {
      url = '/initiate-contract/contract/update'
      delete json.contract.contractStatus
      json.contract['id'] = state.contractId
    }
    json.contract = JSON.stringify(json.contract)
    // 合同添加
    httpToken({
      method: "post",
      url: url,
      data: qs.stringify(json)
    }).then((res) => {
      if (status == 4) {//草稿箱
        router_push_name('operation_sign', undefined, undefined, true)
      } else {
        signSettingClick(res.data)
      }
      state.loading = false
    }).catch(() => {
      state.loading = false
    })
  })
  // /contract/add 合同添加
  // /contract/addCPWList 合同添加后返回的合同id与文件数据一起提交
}
const submitForm = async (formEl, status) => {
  let signatoryData =unref(signatoryRef).getData()
  if (signatoryData.length == 0) {
    handleMessage('请先添加签署方')
    return
  }
  // let companyNames=[]
  // type 0 通讯录添加 1手填 2批量导入 3模板
  // signerType 0个人 1公司
  // signatoryData.forEach(item=>{
  //   if(item.signerType==1){
  //     item.contractSignPersonList.forEach(item2=>{
  //       if(!companyNames.includes(item2).companyName){

  //       }

  //     })

  //   }
  // })
  console.log('signatoryData',signatoryData)
  if (state.filesContracts.length == 0) {
    handleMessage('至少添加一份签约文件')
    return
  }
  await unref(signatoryRef).submitForm().then(res => {//签署方数据校验
    if (res == true) {
      console.log('签署方数据校验通过')
      if (!formEl) return;
      formEl.validate((valid, fields) => {//发起合同数据校验
        if (valid) {
          initiateContract(status)
          console.log("submit!");
        } else {
          console.log("error submit!", fields);
        }
      });
    }
  })
};
</script>

<style lang="scss" scoped>
.textareaReset {
  ::v-deep .el-textarea__inner {
    box-shadow: none;
    resize: none;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 15px;
  }
}

.formbox {
  padding: 32px 37px 20px;
  background-color: #fff;

  .el-select {
    width: 226px;
  }

  .el-input {
    width: 455px;
  }

  ::v-deep .el-form-item__content {
    margin-left: 37px;
  }

  ::v-deep .el-form-item__label {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    font-family: PingFangSC-Medium, PingFang SC;
  }
}

.formbox1 {
  ::v-deep .el-input__inner {
    height: 40px;
  }
}

.color1 {
  color: #1677ff;
}
</style>
