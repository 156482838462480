<template>
  <div class="indicate pt-6">
    <el-image class="image" :src="require('@/assets/img/home/left.png')" ></el-image>
    <div class="ml-5 cu-p" @click="router_back()">
      返回上一级
    </div>
    <div class="ml-50 c-999">
      当前位置：<span class="cu-p" @click="router_back()">{{state.backRouteName}}</span> > <span class="c-333">{{state.currentMenuName}}</span>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { useStore } from "vuex";
import {routeLocationKey, useRoute} from 'vue-router'
import {router_back } from '@/utils/server/router.js'
import { hideRoutes2 } from "@/router/route";
const store = useStore();
const route= useRoute()
console.log(route,'useRoute')
let state = reactive({
  currentMenuName:route.meta.name, //当前name
  backRouteName:'',  //父级name
})
// console.log(store.state.backRouteName)
store.state.sidebarMenu2.map(i => {if( i.name == store.state.backRouteName){state.backRouteName =i.meta.name }})
</script>

<style lang="scss" scoped>
.indicate {
  display: flex;
  align-items: center;
  .image {
    height: 16px;
  }
}
</style>